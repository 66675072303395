import * as React from "react";
import { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "../link";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { mutateUser } from "../../hooks/useUser.ts";
import { Box, CircularProgress } from "@mui/material";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import {apiClient} from "../../services/apiClient.ts";

interface SignUpProps {
  code: string | undefined
}

export default function SignUp({code}: SignUpProps) {
  const [csrf, setCSRF] = useState("");
  const [error, setError] = useState("");
  const [saving, setSaving] = useState(false);

  const getCSRF = () => {
    fetch(import.meta.env.VITE_NODE + "/api/csrf/", {
      credentials: "include",
    })
      .then((res) => {
        const csrfToken = res.headers.get("X-CSRFToken");
        if (csrfToken) {
          setCSRF(csrfToken);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getSession();
  }, []);

  const getSession = () => {
    fetch(import.meta.env.VITE_NODE + "/api/session/", {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.isAuthenticated) {
          navigate("/app/news");
        } else {
          getCSRF();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const navigate = useNavigate();

  const isResponseOk = (response: any) => {
    if (response.status >= 200 && response.status <= 299) {
      return response.json();
    } else {
      return response.json().then((data: any) => {
        console.error(data);
        throw Error(JSON.stringify(data));
      });
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const snack = enqueueSnackbar(
      <>
        Signing up&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setSaving(true);

    fetch(import.meta.env.VITE_NODE + "/api/register/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": csrf,
      },
      credentials: "include",
      body: JSON.stringify({
        email: data.get("email"),
        password: data.get("password"),
        fullName: data.get("fullName"),
      }),
    })
      .then(isResponseOk)
      .then((data) => {
        //navigate("/app/news");
        if (code) {
          apiClient().allInviteUseInviteCodeCreate({code: code || ""})
        }
        enqueueSnackbar("Account Created", { variant: "success" });
        mutateUser();
        getSession();
        navigate("/register");
        setError("");
      })
      .catch((err: Error) => {
        console.error(err);
        let message = err.toString();
        if (message.includes("unique")) {
          message = "Email already in use. Please try logging in.";
        }
        enqueueSnackbar("Error creating account: " + message, {
          variant: "error",
        });
        setError(message);
      })
      .finally(() => {
        setSaving(false);
        closeSnackbar(snack);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1 }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="name"
                name="fullName"
                required
                fullWidth
                id="fullName"
                label="Full Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            {/*<Grid item xs={12}>*/}
            {/*  <TextField*/}
            {/*    required*/}
            {/*    fullWidth*/}
            {/*    name="password"*/}
            {/*    label="Password"*/}
            {/*    type="password"*/}
            {/*    id="password"*/}
            {/*    autoComplete="new-password"*/}
            {/*  />*/}
            {/*</Grid>*/}
          </Grid>
          <Button
            type="submit"
            fullWidth
            disabled={saving}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Box sx={{ color: "red" }}>
            <pre>{error}</pre>
          </Box>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
