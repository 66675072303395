import React from "react";
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Link from "../link";

const GettingStarted = () => {
  return (
    <Container maxWidth="lg">
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Overview</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Following this guide will help you with everything needed to get started with running your first class.
            This guide is a work in progress.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Creating Your First Class</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can visit <Link
              href={`/admin/classes`}
          >
            the admin site
          </Link> to view and manage your classes. On this page you can create new classes.
            After creating a new class the 4 nations for that class, (Ag, Lumber, Oil, and Mineral) will all automaticallly be created

          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Adding Users</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            On the <Link
              href={`/admin/users`}
          >
            users admin page
          </Link> you can add your students to your classes. Students can be added individually or by uploading a csv file.
            Clicking "EXAMPLE IMPORT FILE" will output an example file of how the csv import should look.
            A 1 is used for each cell to indicate which roles and team that user should be on.
            For example the user john@example.com will have a "1" in the prime_minister cell to indicate he is a prime minister
            and in the ag cell to indicate he is a part of the ag nation.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default GettingStarted;
