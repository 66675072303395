import * as React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import useSWR, { Fetcher } from "swr";
import { Nation } from "../../my-api-client";
import { apiClient } from "../../services/apiClient";
import { useUser } from "../../hooks/useUser";

interface NationDropdownProps {
  selected: number | null;
  onChange: any;
  excluded: number[];
}

export default function NationDropdown({
  selected,
  onChange,
  excluded,
}: NationDropdownProps) {
  const data = useUser();
  const nationsFetcher: Fetcher<Nation[]> = () => {
    return apiClient()
      .allNationsList()
      .then((res) => {
        return res.data;
      });
  };

  const { data: nations, error } = useSWR<Nation[], Error>(
    apiClient().allNationsList.name,
    nationsFetcher,
  );

  return (
    <>
      <FormControl fullWidth>
        <Select value={selected?.toString() || ''} onChange={onChange}>
          {nations
            ?.filter((nation) => nation.id !== data?.nation.id)
            .map((nation) => {
              return (
                <MenuItem
                  key={nation.id}
                  value={nation.id}
                  disabled={excluded.indexOf(nation.id) > -1}
                >
                  {nation.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </>
  );
}
