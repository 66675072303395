import React from "react";
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useSWR from "swr";
import { Role} from "../../my-api-client";
import {apiClient} from "../../services/apiClient.ts";

const Help = () => {
  const { data: roles } = useSWR<Role[], Error>(
      apiClient().allRolesList.name,
      function () {
        return apiClient()
            .allRolesList()
            .then((res) => {
              return res.data;
            });
      },
  );

  return (
    <Container maxWidth="lg">
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Overview</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Work together with your team to build up your nation. Nations are
            given awards for how well they perform in various categories. More
            details about this in the nations section. This guide will provide
            details on the various sections of Geostrat
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Roles</Typography>
        </AccordionSummary>
        <AccordionDetails>
            {
                roles?.map((role) => {
                    return (
                    <Box key={role.id} sx={{mb: 1}} >
                        <Typography variant="h6">{role.name}</Typography>
                        <Typography>{role.description}</Typography>
                    </Box>
                    );
                })
            }
        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">News</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Come here to read about current events happening in your world.
            Statements can be published here by the secretary of state for all
            nations to see.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Nations</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Find information about the nations of your world here. Here the
            Comptroller is responsible for uploading your nations flag.
            Recommended height to width ratio is 2:3. The comptroller is also
            responsible for editing your nations constitution. On this page you
            will find your nations resources listed. Resources are used for
            developing your nation. Finally you will find information on how
            your nation is performing. These stats are influenced by the actions
            of your nation.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Diplomacy</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" paragraph>
            Here your nation can manage relationships with other nations by
            making alliances or declaring wars. Alliances and declarations must
            be initialized by one party member and approved by another before
            being sent.
          </Typography>

          {/* Trade Alliances */}
          <Typography variant="h6">Trade Alliances</Typography>
          <Typography variant="body2" paragraph>
            Create an agreement with another nation on how your nations will
            agree to trade, import and export resources and goods. This alliance
            can be initiated, approved, and accepted by the Chief Financial
            Officer, Prime Minister, and Comptroller.
          </Typography>

          {/* Military Alliances */}
          <Typography variant="h6">Military Alliances</Typography>
          <Typography variant="body2" paragraph>
            Create an agreement with another nation on how your nations will
            agree to use their military resources. This alliance can be
            initiated, approved, and accepted by the Defense Minister, Prime
            Minister, and Secretary of State.
          </Typography>

          {/* Warning Declarations */}
          <Typography variant="h6">Warning Declarations</Typography>
          <Typography variant="body2" paragraph>
            If another nation is acting in a way that is not in the best
            interest of your nation, you can issue a warning to them. This
            warning can be initiated by the Defense Minister or Secretary of
            State. This warning can be approved by the Prime Minister, Defense
            Minister, or Comptroller.
          </Typography>

          {/* War Declarations */}
          <Typography variant="h6">War Declarations</Typography>
          <Typography variant="body2" paragraph>
            If a nation has ignored your warnings and you feel you must act you
            may declare war against that nation. This declaration can be
            initiated by the Defense Minister. This declaration can be approved
            by the Prime Minister or Secretary of State.
          </Typography>

          {/* Subpoena Declarations */}
          <Typography variant="h6">Subpoena Declarations</Typography>
          <Typography variant="body2" paragraph>
            A subpoena can be sent to force a nation to testify in court. This
            declaration can be initiated and approved by the Secretary of State,
            Prime Minister, Defense Minister, or Comptroller.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Trade</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Here you can make trades with other nations. Your nation may have an
            abundance of one resource but be lacking in another resource. Make
            trades so that you are able to continue developing your nation.
            Trades can be made and accepted by the Chief Financial Officer
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Development</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Here you can spend resources to build infrastructure or build up
            your military. Each resource will improve stats for your nation. For
            example building a hospital will improve your nations health. Some
            items are used to harvest resources. Building a tractor will help
            produce agriculture for your nation. The Chief Financial Officer is
            responsible for purchasing infrastructure. These purchases can be
            approved by the Prime Minister. The Defense Minister is responsible
            for purchasing military resources. These can be approved by the
            Prime Minister.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Map</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography  variant="body2" paragraph>
            Here you can view your world. On this page you will need to claim
            your territory. After your territory is claimed you may place your
            starting village. You will also come here to place your
            infrastructure and military onto the map.

          </Typography>

          <Typography variant="h6">Infrastructure</Typography>
          <Typography variant="body2" paragraph>
            Placing infrastructure will help improve your nations stats and help your nation gain more resources.
          </Typography>
          <Typography variant="h6">Military</Typography>
          <Typography variant="body2" paragraph>
            Military can be used to defend your nation or attack other nations.
            Land and air based military must initially be placed next to a military base.
            Sea based military must initially be placed next to a port.
          </Typography>
          <Typography variant="h6">Moving</Typography>
          <Typography variant="body2" paragraph>
            Your military items may be moved once every 24 hours by your defense minister.
            If your military is placed adjacent to another nations infrastructure and your military presence around the structure is stronger than theirs
            then your nation will take control of that infrastructure.
          </Typography>
          <Typography variant="h6">Attacking</Typography>
          <Typography variant="body2" paragraph>
            The defense minister can request an attack against adjacent military which must be approved by your prime minister.
            In an attack your military will roll dice according to it's current strength against the targets current strength.
            The dice are then compared and each nation will lose strength accordingly.  If a military is reduced to 0 strength it will be destroyed.
            The Nuclear Weapon is placed the same as other military items. This weapon has unlimited range and is a one time use. It will attack and destroy an area of 7 hexes.
            If the target nation has a Nuclear Defense System then the outcome will be determined by the winner of a dice roll.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/*<Accordion>*/}
      {/*  <AccordionSummary expandIcon={<ExpandMoreIcon />}>*/}
      {/*    <Typography variant="h6">Community</Typography>*/}
      {/*  </AccordionSummary>*/}
      {/*  <AccordionDetails>*/}
      {/*    <Typography>*/}
      {/*      This is a place to communicate with your nation as well as other*/}
      {/*      nations*/}
      {/*    </Typography>*/}
      {/*  </AccordionDetails>*/}
      {/*</Accordion>*/}
    </Container>
  );
};

export default Help;
