/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import * as React from "react";
import { ChangeEvent, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  SelectChangeEvent,
} from "@mui/material";
import TextareaAutosize from '@mui/material/TextareaAutosize';

import NationDropdown from "../NationDropdown";
import { myMutate, useUser } from "../../hooks/useUser";
import useSWR, { Fetcher } from "swr";
import { TradeAlliance, TradeStatusStatusEnum } from "../../my-api-client";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { PermissionResult } from "../../services/permissionService";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import GeoButton from "../GeoButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface DeclarationModalInputProps {
  allianceCreate: (
    tradeAllianceRequest: any,
    options?: AxiosRequestConfig<any> | undefined,
  ) => Promise<AxiosResponse<TradeAlliance, any>>;
  listCall: (
    options?: AxiosRequestConfig<any> | undefined,
  ) => Promise<AxiosResponse<TradeAlliance[], any>>;
  allianceTypeTitle: string;
  allianceTypeText: string;
  disabled: PermissionResult;
}

export default function DeclarationModal({
  allianceCreate,
  allianceTypeTitle,
  allianceTypeText,
  disabled,
  listCall,
}: DeclarationModalInputProps) {
  const [saving, setSaving] = useState(false);
  const user = useUser();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [allianceType, setAllianceType] = useState("public");
  const [selectedNation, setSelectedNation] = useState<number|null>(null);
  const [declaration, setDeclaration] = useState("");
  const [disabledNations, setDisabledNations] = useState<number[]>([]);

  const handleNationChange = (event: SelectChangeEvent<number>) => {
    setSelectedNation(event.target.value as number);
  };

  const handleDeclarationChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setDeclaration(event.target.value as string);
  };

  const tradeAlliancesFetcher: Fetcher<TradeAlliance[]> = () => {
    return listCall().then((res) => {
      return res.data;
    });
  };

  const { data: alliances, error } = useSWR<TradeAlliance[], Error>(
    listCall.name.replace("bound ", ""),
    tradeAlliancesFetcher,
  );

  useEffect(() => {
    if (alliances) {
      const existing = alliances.filter(
        (alliance) =>
          (alliance.offeringNationId === user?.nation.id ||
            alliance.receivingNationId === user?.nation.id) &&
          [
            TradeStatusStatusEnum.Created.toString(),
            TradeStatusStatusEnum.SenderAccepted.toString(),
            TradeStatusStatusEnum.ReceiverAccepted.toString(),
          ].indexOf(alliance.status!) > -1,
      );
      if (existing.length > 0) {
        const results: number[] = [];
        existing.forEach((alliance) => {
          results.push(alliance.offeringNationId);
          results.push(alliance.receivingNationId);
        });
        setDisabledNations(results);
      }
    }
  }, [alliances]);

  const submitAlliance = () => {
    const snack = enqueueSnackbar(
      <>
        Saving&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <CircularProgress />
      </>,
      {
        variant: "info",
        persist: true,
      },
    );
    setSaving(true);
    allianceCreate({
      offeringNationId: user?.nation.id || 0,
      receivingNationId: selectedNation,
      declaration,
      isSecret: allianceType === "secret",
    })
      .then((r) => {
        enqueueSnackbar("Saved", { variant: "success" });
        myMutate(listCall.name.replace("bound ", ""));
        handleClose();
        setDeclaration("");
        setSelectedNation(null);
      })
      .catch((e) => {
        enqueueSnackbar("Error: " + e.toString(), {
          variant: "error",
        });
      })
      .finally(() => {
        setSaving(false);

        closeSnackbar(snack);
      });
  };

  return (
    <>
      <Button variant={"outlined"} onClick={handleOpen} sx={{ mb: 1 }}>
        {allianceTypeTitle}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            component={"div"}
          >
            {user?.nation.name} would like to {allianceTypeText}
            <NationDropdown
              excluded={disabledNations}
              selected={selectedNation}
              onChange={handleNationChange}
            />
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Declaration
          </Typography>
          <TextareaAutosize
            css={css`
              width: 100%;
            `}
            minRows={6}
            onChange={handleDeclarationChange}
            value={declaration}
            placeholder=""
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>

          <GeoButton
              title={disabled.message || (selectedNation == null ? "Must select a nation" : (declaration.trim().length < 100 ? "Declaration must be at least 100 characters" : ""))}
              disabled={!disabled.success || selectedNation == 0 || !declaration.trim() || saving}
              onClick={submitAlliance}
          >
            Sign
          </GeoButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
