/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useUser } from "../../hooks/useUser";
import ImageBox from "../ImageBox";
import React from "react";
import Constitution from "../Constitution";
import { permissionService } from "../../services/permissionService";
import Link from "../link";
import { Article, Nations, NationStat, Role } from "../../my-api-client";
import useSWR from "swr";
import { apiClient } from "../../services/apiClient.ts";
import List from "@mui/material/List";
import {
    Box,
  Accordion, AccordionDetails, AccordionSummary,
  ButtonBase,
  Card,
  CardContent,
  CardMedia,
  ListItem,
  ListItemText,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import NationStatsDisplay, { Stat } from "./nationStatsDisplay.tsx";
import LoadingScreen from "../Loading/loadingScreen.tsx";
import NationPowerCardsDisplay from "./nationPowerCardsDisplay.tsx";
import NationName from "./nationName.tsx";
import GeoWrapperTooltip from "../GeoWrapperTooltip";

export default function NationContent({
                                        nation,
                                        stats,
                                        adminOpen,
                                        setAdminOpen,
                                        resourcesOpen,
                                        setResourcesOpen,
                                        statsOpen,
                                        setStatsOpen,
                                        powerOpen,
                                        setPowerOpen
                                      }: {
  nation: Nations,
  stats: NationStat,
  adminOpen: boolean,
  setAdminOpen: (value: (((prevState: boolean) => boolean) | boolean)) => void,
  resourcesOpen: any,
  setResourcesOpen: any,
  statsOpen: any,
  setStatsOpen: any,
  powerOpen: any,
  setPowerOpen: any
}) {
  const user = useUser();
  const { data: roles, error } = useSWR<Role[], Error>(
    apiClient().allRolesList.name,
    function () {
      return apiClient()
        .allRolesList()
        .then((res) => {
          return res.data;
        });
    },
  );

  const nationStats2: Stat[] = [];

  for (let i = 0; i < stats?.stats?.length || 0; i++) {
    nationStats2.push({
      title: stats.stats[i].name,
      value: stats.stats[i].displayValue,
      progress: stats.stats[i].value,
        description: stats.stats[i].description,
    });
  }

  if (!user) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Card
        sx={{ maxWidth: 800, margin: "auto", width: "100%" }}
        variant="outlined"
      >
        <Box sx={{ height: '200px' }}>
        <ImageBox
          image={nation.imageUrl}
          field={"flag"}
          upload={`nations/${nation.id}/`}
          canEdit={permissionService.canUploadFlag(user, nation).success}
          circle={false}
        />
        </Box>
        <CardContent>
          <NationName nation={nation} />

          <Constitution nation={nation} />

            <Accordion expanded={adminOpen} onChange={() => {
              setAdminOpen(!adminOpen);
            }}>
              <AccordionSummary>
                Administration
              </AccordionSummary>
              <AccordionDetails>
          <Typography variant="body2" component="div" gutterBottom>
            <List>
              {roles?.map((role, index) => (
                <React.Fragment key={role.id}>
                  <ListItem>
                    <ListItemText primary={role.name} />
                  </ListItem>
                  <List disablePadding>
                    {nation.users
                      .filter(
                        (user) =>
                          user.roles.filter(
                            (userRole) => userRole.id == role.id,
                          ).length > 0,
                      )
                      .map((user) => {
                        return (
                          <ListItem key={user.id} sx={{ pl: 4 }} disablePadding>
                            <Link
                              href={`/app/profile/${user.id}`}
                              css={css`
                                cursor: pointer;
                                font-weight: 500;
                              `}
                            >
                              <ListItemText primary={user.fullName} />
                            </Link>
                          </ListItem>
                        );
                      })}
                  </List>
                  {index < roles.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={resourcesOpen} onChange={() => {
                setResourcesOpen(!resourcesOpen);
            }}>
                <AccordionSummary>
                    Resources
                </AccordionSummary>
                <AccordionDetails>
          <Typography variant="body2" component="div">
            {nation.resources && nation.resources.length ? (
              <List>
                {nation.resources.map((resource) => {
                  return (
                    <ListItem key={resource.id}>
                      <ListItemIcon>
                        <img
                          width={20}
                          height={20}
                          alt="image"
                          src={`${import.meta.env.VITE_CDN}${
                            resource.resourceType.icon
                          }`}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={resource.resourceType.name}
                        secondary={`Quantity: ${resource.total}`}
                      />
                    </ListItem>
                  );
                })}
              </List>
            ) : (
              ""
            )}
          </Typography>
                        </AccordionDetails>
            </Accordion>

            <Accordion expanded={statsOpen} onChange={() => {
                setStatsOpen(!statsOpen);
            }}>
                <AccordionSummary>
                    Nation Stats
                </AccordionSummary>
                <AccordionDetails>
                    <NationStatsDisplay nationId={nation.id} stats={nationStats2} />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={powerOpen} onChange={() => {
                setPowerOpen(!powerOpen);
            }}>
                <AccordionSummary>
                    Power Points
                </AccordionSummary>
                <AccordionDetails>
          <NationPowerCardsDisplay nationStat={stats} />
          <List>
                <ListItem divider sx={{ justifyContent: "space-between" }}>
                    <GeoWrapperTooltip title="The points from all the infrastructure controlled on the map" placement="top">
                        <ListItemText primary={`Points From Infrastructure: ${stats.buildingPoints}`} />
                    </GeoWrapperTooltip>
                </ListItem>
            <ListItem divider sx={{ justifyContent: "space-between" }}>
              <ListItemText primary={`Total Power Points: ${stats.powerPoints}`} />
            </ListItem>

          </List>
                </AccordionDetails>
            </Accordion>
        </CardContent>
      </Card>
    </>
  );
}
